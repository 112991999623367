import React, { useContext, FC, useEffect, useState, useRef } from 'react';
import { BookendsWrapper } from '@vp/digital-bookends-lib';
import { isProduction } from '@vp/digital-environment-lib';
import { BoundedContent } from '@vp/swan';
import { Breadcrumbs } from '@vp/breadcrumbs-react-component';
import { WixPageAnalyticsContext } from '../../helpers/WixPageAnalyticsProvider';
import { MainLayout, RootLayout } from '@vp/digital-site-layout-lib';
import {
  CIMPRESS_INTERNAL,
  IS_CUSTOMER,
  useIdentity,
  useWebAuth,
} from '@vp/digital-auth-lib';
import Loading from './Loading';
import { getLogger } from '@vp/digital-logging-lib';
import { WIX_WEBSITES_MPV_ID } from '@vp/digital-product-availability-lib';
import { ScriptKeys, StyleKeys } from '../../swanKeys';
import { Helmet } from 'react-helmet';

import '@vp/digital-bookends-lib/dist/index.css';

type layoutProps = {
  renderMetaWith?: React.ComponentType<any>;
  isSlimHeader: boolean;
  isSlimFooter: boolean;
  breadCrumbs: boolean;
  locale?: string;
  requireAuth?: boolean;
  isInternalUserView?: boolean;
  hideFooter?: boolean;
  hideHeader?: boolean;
  children?: React.ReactNode;
  tenant?: string;
  displayHelpLink?: boolean;
};
const Layout: FC<layoutProps> = ({
  renderMetaWith,
  isSlimHeader,
  isSlimFooter,
  breadCrumbs,
  locale,
  requireAuth = false,
  isInternalUserView,
  children,
  hideFooter,
  hideHeader,
  tenant = 'vistaprint',
  displayHelpLink = true,
}) => {
  const { trackingConfigData } = useContext(WixPageAnalyticsContext);
  const webAuth = useWebAuth();
  const { isSignedIn, profile } = useIdentity();
  const [isAuthorized, setAuthorized] = useState(!requireAuth);
  const { current: logger } = useRef(getLogger({ page: WIX_WEBSITES_MPV_ID }));

  let ignoreSignIn = false;

  if (typeof window !== 'undefined' && window.location?.search) {
    const urlParams: URLSearchParams = new URLSearchParams(
      window.location.search
    );

    ignoreSignIn = urlParams?.get('ignoreSignIn')?.toLowerCase() === 'true';
  }

  useEffect(() => {
    if (!requireAuth) {
      setAuthorized(true);
      return;
    }
    if (!webAuth) {
      return;
    }
    if (!isSignedIn && (isProduction() || !ignoreSignIn)) {
      webAuth?.signIn();
    } else if (
      profile &&
      ((profile[IS_CUSTOMER] && isInternalUserView) ||
        (profile[CIMPRESS_INTERNAL] && !isInternalUserView))
    ) {
      logger.warn(
        `User Is Signed in using a different Client. Trying to sign in again`
      );
      webAuth.signOut();
      webAuth.signIn();
    } else {
      setAuthorized(true);
    }
  }, [
    ignoreSignIn,
    isInternalUserView,
    isSignedIn,
    logger,
    profile,
    requireAuth,
    webAuth,
  ]);
  if (!isAuthorized) {
    return <Loading />;
  }
  return (
    <>
      <BookendsWrapper
        trackingConfiguration={trackingConfigData}
        locale={locale}
        renderMetaWith={Helmet}
        isSlimHeader={isSlimHeader}
        isSlimFooter={isSlimFooter}
        hideFooter={hideFooter}
        hideHeader={hideHeader}
        tenant={tenant}
        displayHelpLink={displayHelpLink}
      >
        <RootLayout swanStyleKeys={StyleKeys} swanScriptKeys={ScriptKeys}>
          <MainLayout>
            {breadCrumbs && (
              <BoundedContent>
                <Breadcrumbs data={breadCrumbs} />
              </BoundedContent>
            )}
            {children}
          </MainLayout>
        </RootLayout>
      </BookendsWrapper>
    </>
  );
};

export default Layout;

/// <reference path='./environment.d.ts'/>
import {
  getMfeLoggingAttributes,
  LogLevel,
  initConsoleLogger,
} from '@vp/digital-logging-lib';
import { Environment, getClientEnvironment } from '@vp/digital-environment-lib';
import { initNewRelicLogger } from '@vp/digital-newrelic-logging-lib';

const { name, version } = require('./package.json');

export function initNodeLogger() {
  const attributes = getMfeLoggingAttributes(
    name,
    version,
    'gatsby-node',
    process.env.GATSBY_ENVIRONMENT
  );
  const loggerOptions = {
    desiredLogLevel: LogLevel.error,
  };

  initConsoleLogger(attributes, loggerOptions);
}

export function initSsrLogger() {
  const attributes = getMfeLoggingAttributes(
    name,
    version,
    'gatsby-ssr',
    process.env.GATSBY_ENVIRONMENT
  );
  const loggerOptions = {
    desiredLogLevel: LogLevel.error,
  };

  initConsoleLogger(attributes, loggerOptions);
}

export function initBrowserLogger() {
  const environment = getClientEnvironment();
  const attributes = getMfeLoggingAttributes(
    name,
    version,
    'gatsby-browser',
    environment
  );
  const loggerOptions = {
    desiredLogLevel: LogLevel.info,
  };
  if (environment === Environment.Local) {
    initConsoleLogger(attributes, loggerOptions);
  } else {
    initNewRelicLogger(
      process.env.GATSBY_NEW_RELIC_INGESTION_BASE_URL,
      process.env.GATSBY_NEW_RELIC_LOGGING_LICENSE_KEY,
      1000,
      attributes
    );
  }
}

// File to define common Gatsby browser & ssr function(s)
import React from 'react';
import { LocaleProvider } from '@vp/digital-locale-lib';
import { AuthProvider } from '@vp/digital-auth-lib';
import {
  RuntimeContextProvider,
  getClientEnvironment,
} from '@vp/digital-environment-lib';
import {
  PricingContextProvider,
  PricingContentProvider,
} from '@vp/digital-pricing-lib';
import { UrlContextProvider } from '@vp/digital-url-context-lib';
import { RootLayout, HtmlAttributes } from '@vp/digital-site-layout-lib';

import { StyleKeys, ScriptKeys } from './src/swanKeys';

import { WixPageAnalyticsProvider } from './src/helpers/WixPageAnalyticsProvider';

import Layout from './src/components/common/Layout';

import { Helmet } from 'react-helmet';

const ProductPageWrapper = ({ pageContext, location, children }) => {
  const {
    locale,
    billingPeriods,
    directAccessClientId,
    pageAnalyticsData,
    productKey,
    towerBreadCrumbs,
    isSlimHeader,
    isSlimFooter,
    directAccessConnection,
    requireAuth,
    isInternalUserView,
    hideFooter,
    hideHeader,
    isWixLogo,
    displayHelpLink,
  } = pageContext;
  return (
    <LocaleProvider rawLocaleIdentifier={locale}>
      <RuntimeContextProvider getEnvironment={getClientEnvironment}>
        <AuthProvider
          directAccessClientId={directAccessClientId}
          directAccessConnection={directAccessConnection}
        >
          <PricingContextProvider>
            <PricingContentProvider pricingContent={billingPeriods}>
              <UrlContextProvider location={location}>
                <RootLayout
                  swanStyleKeys={StyleKeys}
                  swanScriptKeys={ScriptKeys}
                >
                  <HtmlAttributes />
                  <WixPageAnalyticsProvider
                    pageAnalyticsData={pageAnalyticsData}
                    productKey={productKey}
                  >
                    <Layout
                      renderMetaWith={Helmet}
                      isSlimHeader={isSlimHeader}
                      isSlimFooter={isSlimFooter}
                      breadCrumbs={towerBreadCrumbs}
                      locale={locale}
                      requireAuth={requireAuth}
                      isInternalUserView={isInternalUserView}
                      hideFooter={hideFooter}
                      hideHeader={hideHeader}
                      tenant={isWixLogo ? 'wix' : 'vistaprint'}
                      displayHelpLink={displayHelpLink}
                    >
                      {children}
                    </Layout>
                  </WixPageAnalyticsProvider>
                </RootLayout>
              </UrlContextProvider>
            </PricingContentProvider>
          </PricingContextProvider>
        </AuthProvider>
      </RuntimeContextProvider>
    </LocaleProvider>
  );
};

export const wrapPageElement = ({ element, props }) => {
  const {
    pageContext: { wrap },
  } = props;
  return wrap ? (
    <ProductPageWrapper {...props}>{element}</ProductPageWrapper>
  ) : (
    element
  );
};

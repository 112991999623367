import React from 'react';
import { invariant } from '@vp/digital-invariant-lib';

var UrlContext = React.createContext(undefined);
var VISTAPRINT_URL_PREFIX = 'vistaprint';
function UrlContextProvider(_a) {
    var location = _a.location, children = _a.children;
    var isVistaprintWebsite = false;
    var _b = location || {}, hostname = _b.hostname, pathname = _b.pathname;
    if (hostname)
        isVistaprintWebsite = hostname.includes(VISTAPRINT_URL_PREFIX);
    var contextValue = {
        isVistaprintWebsite: isVistaprintWebsite,
        hostname: hostname,
        pathname: pathname,
    };
    return (React.createElement(UrlContext.Provider, { value: contextValue }, children));
}
function useUrlContext() {
    var context = React.useContext(UrlContext);
    invariant(context, 'useUrlContext must be used within a UrlContext');
    return context;
}

export { UrlContextProvider, useUrlContext };

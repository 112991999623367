import {
  VPTracking,
  TrackingOptions,
  TrackingPageDept,
  TrackingLabel,
  TrackingEventDetail,
  changePlanAttributeDict,
} from './trackingModels';
import TrackingJS from '@vp/tracking';
import { normalizeLocale } from '@vp/digital-locale-lib';
import { isNone } from 'fp-ts/lib/Option';
import { getNoTrackWriteKey } from '@vp/tracking-key-provider';

import { getClientEnvironment, Environment } from '@vp/digital-environment-lib';

import getLibLogger from './getLibLogger';

const GENTRACK_TRIBE_DEFAULT = 'Digital Tribe';
const GENTRACK_VILLAGE_DEFAULT = 'Acquisition Village';

const GENTRACK_DEFAULT = 'UNINITIALIZED_GENTRACK';
const COUNTRY_DEFAULT = 'UNINITIALIZED_COUNTRY';
const LANGUAGE_DEFAULT = 'UNINITIALIZED_LANGUAGE';

// optional field for internal use - can be used to send responsible Tribe's name
// see https://vistaprint.atlassian.net/wiki/spaces/wacoe/pages/58557312/Product+Funnel+Tracking+specification+for+Google+Analytics
let genTrack: string = GENTRACK_DEFAULT;

// These are effectively state variables to store results after initializing
// the tracking library
let country: string = COUNTRY_DEFAULT;
let language: string = LANGUAGE_DEFAULT;

// Array of environments that should generate metrics
type ActiveEnvironmentArray = Array<Environment>;
const activeTrackingEnvironments: ActiveEnvironmentArray = [
  Environment.Local,
  Environment.Staging,
  Environment.Prod,
];

const trackingLibLogger = getLibLogger();
// Wrapper for standard @vp/tracking base implementation
function getTracking(): VPTracking | undefined {
  const tracking: VPTracking = TrackingJS.getTracking();
  if (!tracking) {
    trackingLibLogger.warn('Digital Tracking Library not initialized', {
      function: getTracking.name,
    });
  }
  return tracking;
}

// Function to init the tracking library synchronously
// locale: string specifying the current locale
// keys: A hashtable of environment -> tracking key
type EnvironmentTrackingKeyMap = { [key in Environment]?: string };

function initTrackingWithKeys(
  locale: string,
  keys: EnvironmentTrackingKeyMap,
  assetName: string,
  options?: TrackingOptions
): void {
  // Sanity check params
  if (!locale || !keys) {
    trackingLibLogger.warn(
      'Tracking disabled:  Must provide a valid locale and keys',
      {
        function: initTrackingWithKeys.name,
      }
    );
    return;
  }

  // Determine the environment
  const environment: Environment = getClientEnvironment();
  const environmentShouldGenerateMetrics =
    activeTrackingEnvironments.includes(environment);

  // Update gentrack
  genTrack = determineGenTrack(assetName, options);

  const optionedLocale = normalizeLocale(locale);
  if (isNone(optionedLocale)) {
    trackingLibLogger.error(
      'Unable to normalize locale.  Tracking may be affected.',
      {
        function: initTrackingWithKeys.name,
        locale,
      }
    );
  } else {
    country = optionedLocale.value.countryCode;
    language = optionedLocale.value.languageCode;
  }

  // Get the write key
  let writeKey: string;
  if (environmentShouldGenerateMetrics && environment in keys) {
    writeKey = keys[environment];
  } else {
    writeKey = getNoTrackWriteKey();
    trackingLibLogger.info('Analytics are disabled for country / environment', {
      function: initTrackingWithKeys.name,
      country,
      environment,
    });
  }

  TrackingJS.init(writeKey);
  trackingLibLogger.info('Tracking initalized', {
    function: initTrackingWithKeys.name,
    locale,
  });
}

// Creates the genTrack string by examining any options passed in
// valid options: (will override default strings)
// * genTrackTribe
// * genTrackVillage
function determineGenTrack(
  assetName: string,
  options: TrackingOptions
): string {
  const tribe: string = options?.genTrackTribe ?? GENTRACK_TRIBE_DEFAULT;
  const village: string = options?.genTrackVillage ?? GENTRACK_VILLAGE_DEFAULT;

  return `${tribe}:${village}:${assetName}`;
}
function determinePageDeptByProductType(productType: string): TrackingPageDept {
  if (productType && changePlanAttributeDict[productType]) {
    return changePlanAttributeDict[productType].pageDept;
  }
  return TrackingPageDept.PAGE_DEPT_UNINITIALIZED;
}
function determineLabelByProductType(productType: string): TrackingLabel {
  if (productType && changePlanAttributeDict[productType]) {
    return changePlanAttributeDict[productType].label;
  }
}
function determineEventDetailByProductType(
  productType: string
): TrackingEventDetail {
  if (productType && changePlanAttributeDict[productType]) {
    return changePlanAttributeDict[productType].eventDetail;
  }
}

export {
  initTrackingWithKeys,
  getTracking,
  country as trackingCountry,
  language as trackingLanguage,
  genTrack,
  determinePageDeptByProductType,
  determineLabelByProductType,
  determineEventDetailByProductType,
};

import * as React from 'react';
import React__default from 'react';
import { Helmet } from 'react-helmet';
import { useLocale } from '@vp/digital-locale-lib';
import { SiteMain, MainContentStart, SWAN_BASE_URL_MAP, useBrowserClasses, getRootClassNames, SwanProvider, SwanHead, ScreenClassProvider, SiteContent, getSwanStylesheetUrl, getSwanJsUrl } from '@vp/swan';

var HtmlAttributes = function () {
    var languageCode = useLocale().languageCode;
    return (React__default.createElement(Helmet, { htmlAttributes: {
            lang: languageCode,
        } }));
};

var MainLayout = function (_a) {
    var children = _a.children;
    return (React__default.createElement(SiteMain, null,
        React__default.createElement(MainContentStart, null),
        children));
};

var StyleLoader = function (_a) {
    var styleUrls = _a.styleUrls;
    return (React__default.createElement(Helmet, null, styleUrls &&
        styleUrls.map(function (url) {
            if (url.includes('https://'))
                return React__default.createElement("link", { key: url, rel: "stylesheet", type: "text/css", href: url });
        })));
};

var SwanConfiguration = function (_a) {
    var children = _a.children, styleKeys = _a.styleKeys;
    var swanBaseUrl = SWAN_BASE_URL_MAP.default.cdn;
    var browserClassName = useBrowserClasses();
    var bodyClassName = getRootClassNames();
    return (React.createElement(SwanProvider, { swanBaseUrl: swanBaseUrl },
        React.createElement(Helmet, { htmlAttributes: { class: browserClassName }, bodyAttributes: { class: bodyClassName } }),
        React.createElement(StyleLoader, { styleUrls: styleKeys }),
        React.createElement(SwanHead, { renderWith: Helmet, renderStyleContentAsChildren: true, styleSheetKeys: styleKeys }),
        React.createElement(ScreenClassProvider, null,
            React.createElement(SiteContent, null, children))));
};

var RootLayout = function (_a) {
    var children = _a.children, swanStyleKeys = _a.swanStyleKeys;
    return (React__default.createElement(React__default.Fragment, null,
        React__default.createElement(SwanConfiguration, { children: children, styleKeys: swanStyleKeys })));
};

var ScriptLoader = function (_a) {
    var scriptUrls = _a.scriptUrls;
    return (React__default.createElement(Helmet, null, scriptUrls &&
        scriptUrls.map(function (url) { return (React__default.createElement("script", { key: url, type: "text/javascript", src: url })); })));
};

var SwanStyleLoader = function (_a) {
    var _b = _a.swanStyleKeys, swanStyleKeys = _b === void 0 ? [] : _b, _c = _a.styleUrls, styleUrls = _c === void 0 ? [] : _c;
    swanStyleKeys.forEach(function (styleKey) {
        return styleUrls.push(getSwanStylesheetUrl(styleKey, undefined, SWAN_BASE_URL_MAP.default.cdn));
    });
    return React__default.createElement(StyleLoader, { styleUrls: styleUrls });
};

var SwanScriptLoader = function (_a) {
    var _b = _a.swanScriptKeys, swanScriptKeys = _b === void 0 ? [] : _b, _c = _a.scriptUrls, scriptUrls = _c === void 0 ? [] : _c;
    swanScriptKeys.forEach(function (styleKey) {
        scriptUrls.push(getSwanJsUrl(styleKey, undefined, SWAN_BASE_URL_MAP.default.cdn));
    });
    return React__default.createElement(ScriptLoader, { scriptUrls: scriptUrls });
};

export { HtmlAttributes, MainLayout, RootLayout, ScriptLoader, StyleLoader, SwanScriptLoader, SwanStyleLoader };

import React, { createContext, useState, useContext, useEffect } from 'react';

var Environment;
(function (Environment) {
    Environment["Prod"] = "Prod";
    Environment["Staging"] = "Staging";
    Environment["Local"] = "Local";
})(Environment || (Environment = {}));
var AccessType;
(function (AccessType) {
    AccessType["Direct"] = "Direct";
    AccessType["Proxy"] = "Proxy";
    AccessType["None"] = "None";
})(AccessType || (AccessType = {}));

var theEnvironment;
function getQueryEnvironment() {
    var _a;
    if (typeof window !== 'undefined') {
        if (window.location && ((_a = window.location) === null || _a === void 0 ? void 0 : _a.search)) {
            var urlParams = new URLSearchParams(window.location.search);
            var queryEnv = urlParams.get('env');
            switch (queryEnv) {
                case 'prod':
                    return Environment.Prod;
                case 'staging':
                    return Environment.Staging;
                case 'local':
                    return Environment.Local;
            }
        }
    }
    return null;
}
function getHostnameEnvironment() {
    var _a, _b;
    if (typeof window !== 'undefined') {
        var hostname = (_b = (_a = window.location) === null || _a === void 0 ? void 0 : _a.hostname) !== null && _b !== void 0 ? _b : '';
        if (hostname.includes('staging.vistaprint') ||
            (hostname.includes('staging.') && hostname.includes('vpsvc.com')) ||
            ((hostname.includes('qa.') || hostname.includes('qa-')) &&
                hostname.includes('vpsvc.com'))) {
            return Environment.Staging;
        }
        if (hostname === 'localhost') {
            return Environment.Local;
        }
    }
    return Environment.Prod;
}
function getClientConfigEnvironment() {
    var queryEnv = getQueryEnvironment();
    var hostEnv = getHostnameEnvironment();
    return queryEnv !== null && queryEnv !== void 0 ? queryEnv : hostEnv;
}
var getClientEnvironment = function (useCache) {
    if (useCache === void 0) { useCache = true; }
    if (!theEnvironment || !useCache) {
        theEnvironment = getClientConfigEnvironment();
    }
    return theEnvironment;
};
var isProduction = function (useCache) {
    if (useCache === void 0) { useCache = true; }
    return getClientEnvironment(useCache) === Environment.Prod;
};

var VP_PROXY_NO_TLD = 'vistaprint';
function getAccessType() {
    var _a, _b;
    if (typeof window !== 'undefined') {
        var hostname = (_b = (_a = window.location) === null || _a === void 0 ? void 0 : _a.hostname) !== null && _b !== void 0 ? _b : '';
        if (hostname.includes(VP_PROXY_NO_TLD)) {
            return AccessType.Proxy;
        }
        else if (hostname.match(/vpsvc.com|localhost/)) {
            return AccessType.Direct;
        }
    }
    return AccessType.None;
}

var RuntimeContextContext = createContext(undefined);
function RuntimeContextProvider(_a) {
    var children = _a.children, _b = _a.getEnvironment, getEnvironment = _b === void 0 ? getClientEnvironment : _b;
    var accessType = useState(getAccessType())[0];
    var environment = useState(getEnvironment())[0];
    return (React.createElement(RuntimeContextContext.Provider, { value: { environment: environment, accessType: accessType } }, children));
}
function useRuntimeContext() {
    var context = useContext(RuntimeContextContext);
    return context;
}

function usePathSuffix() {
    var _a = useRuntimeContext(), environment = _a.environment, accessType = _a.accessType;
    var _b = useState(''), pathSuffix = _b[0], setPathSuffix = _b[1];
    useEffect(function () {
        var pathSuffixValue = accessType === AccessType.Direct && environment !== Environment.Local
            ? '/index.html'
            : '';
        setPathSuffix(pathSuffixValue);
    }, [environment, accessType]);
    return pathSuffix;
}

export { AccessType, Environment, RuntimeContextProvider, getAccessType, getClientEnvironment, isProduction, usePathSuffix, useRuntimeContext };

import { useRef, useCallback, useState, useEffect } from 'react';
import { invariant } from '@vp/digital-invariant-lib';

var LocalStorageProvider = (function () {
    function LocalStorageProvider() {
    }
    LocalStorageProvider.prototype.clear = function () {
        if (typeof window !== 'undefined') {
            window.localStorage.clear();
        }
    };
    LocalStorageProvider.prototype.getItem = function (key) {
        if (typeof window !== 'undefined') {
            var valueJson = window.localStorage.getItem(key);
            if (valueJson) {
                return JSON.parse(valueJson);
            }
        }
        return null;
    };
    LocalStorageProvider.prototype.key = function (index) {
        if (typeof window !== 'undefined') {
            return window.localStorage.key(index);
        }
        return null;
    };
    LocalStorageProvider.prototype.removeItem = function (key) {
        if (typeof window !== 'undefined') {
            window.localStorage.removeItem(key);
        }
    };
    LocalStorageProvider.prototype.setItem = function (key, value) {
        if (typeof window !== 'undefined') {
            if (value) {
                window.localStorage.setItem(key, value);
            }
            else {
                this.removeItem(key);
            }
        }
    };
    return LocalStorageProvider;
}());

function getItem(key, provider) {
    if (typeof window !== 'undefined') {
        var valueJson = (provider || window.sessionStorage).getItem(key);
        if (valueJson) {
            var parsedValue = void 0;
            try {
                parsedValue = JSON.parse(valueJson);
            }
            catch (error) {
                return valueJson;
            }
            return parsedValue;
        }
    }
    return null;
}
function clearItem(key, provider) {
    if (typeof window !== 'undefined') {
        (provider || window.sessionStorage).removeItem(key);
    }
}
function setItem(key, value, provider) {
    if (typeof window !== 'undefined') {
        if (value) {
            var valueJson = JSON.stringify(value);
            (provider || window.sessionStorage).setItem(key, valueJson);
        }
        else {
            clearItem(key, provider);
        }
    }
}

function useStorageRef(key, initialValue, provider) {
    if (initialValue === void 0) { initialValue = null; }
    invariant(key, 'You must assign a unique storage key.');
    var value = useRef(getItem(key, provider) || initialValue);
    var setValue = useCallback(function (newValue) {
        value.current = newValue;
        setItem(key, value.current, provider);
    }, [key, provider]);
    var clearValue = useCallback(function () {
        value.current = null;
        clearItem(key, provider);
    }, [key, provider]);
    return [value.current, setValue, clearValue];
}

function useStorageState(key, initialValue, provider) {
    if (initialValue === void 0) { initialValue = null; }
    invariant(key, 'You must assign a unique storage key.');
    var _a = useState(getItem(key, provider) || initialValue), value = _a[0], setValue = _a[1];
    useEffect(function () {
        setItem(key, value, provider);
    }, [key, value, provider]);
    var clearValue = useCallback(function () { return clearItem(key, provider); }, [key, provider]);
    return [value, setValue, clearValue];
}

export { LocalStorageProvider, useStorageRef, useStorageState };

export enum wixErrorTypeEnum {
  None = 'none',
  GeoLocationError = 'geoLocationError',
  GenericError = 'genericError',
  MissingSiteError = 'missingSiteError',
  MissingPackageError = 'missingPackageError',
  MissingSubscriptionGroupAltId = 'missingSubscriptionGroupAltId',
  WixNotImplementedError = 'wixNotImplementedError',
  ServiceNotAvailable = 'serviceNotAvailable',
  WixNotProvisionedError = 'wixNotProvisionedError',
}

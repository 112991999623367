var PRODUCT_KEY_LISTINGS_MANAGER = 'PRD-QKQ7EW4X';
var PRODUCT_KEY_WEBSITES = 'PRD-O0G66EVT';
var PRODUCT_KEY_DOMAINS = 'PRD-D2XIGWFS';
var PRODUCT_KEY_PRIVACY_PROTECTION = 'PRD-BATJAFNQI';
var SERVICE_LENGTH_ATTRIBUTE_NAME = 'Service Length';
var SERVICE_LENGTH_ONE_MONTH = '1 Month';
var SERVICE_LENGTH_ONE_YEAR = '1 Year';
var SERVICE_LENGTH_TWO_YEAR = '2 Year';
var SERVICE_LENGTH_THREE_YEAR = '3 Year';
var PACKAGE_LEVEL_ATTRIBUTE_NAME = 'Package Level';
var PACKAGE_LEVEL_STARTER = 'Starter';
var PACKAGE_LEVEL_STANDARD = 'Standard';
var PACKAGE_LEVEL_PREMIUM = 'Premium';
var DOMAINS_ATTRIBUTE_NAME = 'Domains';

var getServiceLength = function (productIdentifier) {
    var _a;
    return (_a = productIdentifier.productAttributes.find(function (productAttribute) {
        return productAttribute.key === SERVICE_LENGTH_ATTRIBUTE_NAME;
    })) === null || _a === void 0 ? void 0 : _a.value;
};

export { DOMAINS_ATTRIBUTE_NAME, PACKAGE_LEVEL_ATTRIBUTE_NAME, PACKAGE_LEVEL_PREMIUM, PACKAGE_LEVEL_STANDARD, PACKAGE_LEVEL_STARTER, PRODUCT_KEY_DOMAINS, PRODUCT_KEY_LISTINGS_MANAGER, PRODUCT_KEY_PRIVACY_PROTECTION, PRODUCT_KEY_WEBSITES, SERVICE_LENGTH_ATTRIBUTE_NAME, SERVICE_LENGTH_ONE_MONTH, SERVICE_LENGTH_ONE_YEAR, SERVICE_LENGTH_THREE_YEAR, SERVICE_LENGTH_TWO_YEAR, getServiceLength };

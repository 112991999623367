import { Row, Column, Box, Spinner } from '@vp/swan';
import React from 'react';

const Loading: React.FC = () => {
  return (
    <Row>
      <Column span={12}>
        <Box marginY={8} align="center">
          <Spinner accessibleText="Loading..." />
        </Box>
      </Column>
    </Row>
  );
};

export default Loading;

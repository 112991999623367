import { getLibraryLoggingAttributes, getLogger } from '@vp/digital-logging-lib';

var name = "@vp/digital-invariant-lib";
var version = "0.19.4";

var getLibLogger = function (attributes) {
    var attr = getLibraryLoggingAttributes(name, version, attributes);
    return getLogger(attr);
};

var prefix = 'Invariant failed';
var invariantLogger = getLibLogger();
function invariant(condition, message) {
    if (condition) {
        return;
    }
    invariantLogger.error("".concat(prefix, ": ").concat(message || ''));
    throw new Error("".concat(prefix, ": ").concat(message || ''));
}

export { invariant };
